import { BaseService } from "@app/services/BaseService";
import { ModelCardInterface } from "@dashart/dashart-gpt-shared-library";
import axios, { AxiosResponse } from "axios";
import { ChatCompletionResultInterface } from "../interfaces/ChatCompletionResultInterface";
import { ChatMessageInterface } from "../interfaces/ChatMessageInterface";
import { ChatServiceInterface } from "../interfaces/ChatServiceInterface";
import { AuthUtil } from "../utils/AuthUtil";
import { AxiosUtil } from "../utils/AxiosUtil";

/**
 * Service class for interacting with ChatGPT APIs.
 */
export class OpenAiService extends BaseService implements ChatServiceInterface {

    /** Base name for GPT models, used for history entries detection */
    readonly modelBaseNameStart: string = 'gpt';

    /** Singleton instance of OpenAiService */
    private static _instance: OpenAiService;

    constructor() {
        super();
    }

    protected getConfiguration(): { defaultModel: string; apiResourceName: string; } {
        return {
            defaultModel: 'gpt-4o-mini',
            apiResourceName: 'chat-gpt',
        }
    }

    /**
     * Gets the singleton instance of OpenAiService.
     * @returns Singleton instance of OpenAiService.
     */
    public static getInstance(): OpenAiService {
        return this._instance ?? (this._instance = new OpenAiService());
    }

    /**
     * Retrieves the available chat models.
     * @returns Promise resolving to an array of ChatGptModelInterface that match chat model criteria.
     */
    public async getAvailableChatModels(): Promise<ModelCardInterface[]> {
        return await this.getAvailableModels();
    }

    /**
     * Completes a chat conversation by sending a prompt and receiving a response stream.
     * @param prompt The text prompt to send.
     * @param useServerSideHistory Optional flag to use server-side history.
     * @param previousMessages Optional array of previous messages.
     * @param onResponse Optional callback for each response chunk.
     * @param onFinishCallback Optional callback when the response is fully received.
     * @param model The ID of the model to use.
     * @param maxTokens Optional maximum number of tokens.
     * @param conversationId Optional conversation ID.
     * @param temperature Optional temperature for response generation.
     * @returns Promise resolving to an object containing a cancel function.
     */
    public async chatCompletion(
        prompt: string,
        useServerSideHistory?: boolean,
        previousMessages?: { message: ChatMessageInterface }[],
        onResponse?: (data: any, prompt: string, currentCompletion: string) => void,
        onFinishCallback?: (data: any, prompt: string, currentCompletion: string) => void,
        model: string = `gpt-3.5-turbo`,
        maxTokens?: number,
        conversationId?: string,
        temperature?: number,
    ): Promise<{ cancel: Function }> {
        const modelIsO1Model = model.startsWith('o1');
        const modelGptModel = model.startsWith('gpt');

        if (modelGptModel) {
            // call overridden method
            return super.chatCompletion(
                prompt,
                useServerSideHistory,
                previousMessages,
                onResponse,
                onFinishCallback,
                model,
                maxTokens,
                conversationId,
                temperature
            );
        }

        if (modelIsO1Model) {
            return this.o1ChatCompletionWithoutStream(
                prompt,
                previousMessages,
                onResponse,
                onFinishCallback,
                model,
                maxTokens,
                conversationId,
                temperature
            );
        }
    }

    /**
     * Completes a chat conversation by sending a prompt and receiving a response stream.
     * @param prompt The text prompt to send.
     * @param previousMessages Optional array of previous messages.
     * @param onResponse Optional callback for each response chunk.
     * @param onFinishCallback Optional callback when the response is fully received.
     * @param model The ID of the model to use.
     * @param maxTokens Optional maximum number of tokens.
     * @param conversationId Optional conversation ID.
     * @param temperature Optional temperature for response generation.
     * @returns Promise resolving to an object containing a cancel function.
     */
    public async o1ChatCompletionWithoutStream(
        prompt: string,
        previousMessages?: { message: ChatMessageInterface }[],
        onResponse?: (data: any, prompt: string, currentCompletion: string) => void,
        onFinishCallback?: (data: any, prompt: string, currentCompletion: string) => void,
        model: string = `gpt-3.5-turbo`,
        maxTokens?: number,
        conversationId?: string,
        temperature?: number,
    ): Promise<{ cancel: Function }> {
        return new Promise<{ cancel: Function }>(async (resolve, reject) => {
            const abortController = new AbortController();
            let currentCompletion = '';

            // o1 model doe snot support system messages, so we need to extract them an prepend them to the prompt
            const systemMessages = previousMessages?.filter(message => message.message.role === 'system');
            const systemMessagesText = systemMessages?.map(message => message.message.content).join('\n');

            // messages without system messages
            previousMessages = previousMessages?.filter(message => message.message.role !== 'system');

            const contentOfFirstPreviousMessage = previousMessages?.[0]?.message?.content;

            // prepend system messages to prompt, but only if not already present in first message of previousMessages
            if (systemMessagesText && (!contentOfFirstPreviousMessage || !contentOfFirstPreviousMessage.includes(systemMessagesText))) {
                prompt = systemMessagesText ? `${systemMessagesText}\n\n\n${prompt}` : prompt
            }

            const cancel = () => {
                abortController.abort();
            };

            try {
                await this.checkSessionValidity();

                const response: AxiosResponse<ChatCompletionResultInterface> = await this.axiosInstance.post(
                    `${AxiosUtil.baseURL}/v1/${this.getConfiguration().apiResourceName}/`,
                    {
                        conversationId: conversationId,
                        model: model ?? this.getConfiguration().defaultModel,
                        prompt: prompt,
                        maxTokens: maxTokens,
                        previousMessages: previousMessages ?? [],
                        temperature: temperature,
                        useServerSideHistory: false,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-device': AuthUtil.getDeviceId(),
                            'x-conversation-id': conversationId,
                            'Authorization': `Bearer ${AuthUtil.getAccessToken()}`,
                        },
                        signal: abortController.signal,
                    }
                );

                const data = response.data;
                currentCompletion = data.choices[0].message.content;

                onResponse?.(data, prompt, currentCompletion);
                onFinishCallback?.(data, prompt, currentCompletion);

                resolve({ cancel });
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                } else {
                    reject(error);
                }
            }
        });
    }
}
