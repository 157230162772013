import { VisionPromptContentImageInterface } from "@dashart/dashart-gpt-shared-library/dist/interfaces/VisionPromptContentImageInterface";

export default class ImagePromptUtil {
    /**
     * Image configuration for the vision prompt content.
     * This is used to resize the image to the maximum width and height,
     * and to set the quality and type of the image.
     * 
     * At time of implementation, this is the most efficient image quality for the vision prompt content.
     * 
     * @type {Object}
     * @property {number} MAX_WIDTH - Maximum width constraint
     * @property {number} MAX_HEIGHT - Maximum height constraint
     * @property {number} QUALITY - Quality of the image
     * @property {string} TYPE - Type of the image
     */
    public static readonly IMAGE_CONFIG = {
        MAX_WIDTH: 1024,
        MAX_HEIGHT: 1024,
        QUALITY: 0.7,
        TYPE: 'image/jpeg',
    } as const;

    /**
     * Calculates new dimensions while maintaining aspect ratio
     * @param {number} width - Original width
     * @param {number} height - Original height
     * @param {number} maxWidth - Maximum width constraint
     * @param {number} maxHeight - Maximum height constraint
     * @returns {{ width: number, height: number }} New dimensions
     */
    public static calculateDimensions = (
        width: number,
        height: number,
        maxWidth: number,
        maxHeight: number
    ): { width: number; height: number } => {
        if (width <= maxWidth && height <= maxHeight) {
            return { width, height };
        }

        const ratio = Math.min(maxWidth / width, maxHeight / height);
        return {
            width: Math.floor(width * ratio),
            height: Math.floor(height * ratio)
        };
    };

    /**
     * Resizes an image file to the maximum width and height,
     * and returns the resized image as a VisionPromptContentImageInterface.
     * 
     * @param {File} file - The image file to resize
     * @param {number} maxWidth - Maximum width constraint
     * @param {number} maxHeight - Maximum height constraint
     * @returns {Promise<VisionPromptContentImageInterface>} Resized image
     */
    public static resizeImageFile = async (
        file: File,
        maxWidth: number,
        maxHeight: number
    ): Promise<VisionPromptContentImageInterface> => {
        return this.resizeImageData(await this.getBase64FromFile(file), maxWidth, maxHeight);
    };

    /**
     * Resizes an image using a base64 data string,
     * and returns the resized image as a VisionPromptContentImageInterface.
     * 
     * @param {string} base64Data - The base64 data string of the image to resize
     * @param {number} maxWidth - Maximum width constraint
     * @param {number} maxHeight - Maximum height constraint
     * @returns {Promise<VisionPromptContentImageInterface>} Resized image
     */
    public static resizeImageData = async (
        base64Data: string,
        maxWidth: number,
        maxHeight: number
    ): Promise<VisionPromptContentImageInterface> => {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (!ctx) {
                    reject(new Error('Unable to get canvas context'));
                    return;
                }

                // Calculate dimensions maintaining aspect ratio
                const { width: newWidth, height: newHeight } = this.calculateDimensions(
                    img.width,
                    img.height,
                    maxWidth,
                    maxHeight
                );

                canvas.width = newWidth;
                canvas.height = newHeight;
                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                resolve({
                    imageUrl: canvas.toDataURL(this.IMAGE_CONFIG.TYPE, this.IMAGE_CONFIG.QUALITY),
                    imageType: this.IMAGE_CONFIG.TYPE,
                    imageWidth: newWidth,
                    imageHeight: newHeight
                });
            };

            img.onerror = reject;
            img.src = base64Data;
        });
    };

    /**
     * Converts a File object to a base64 data string.
     * 
     * @param {File} file - The image file to convert
     * @returns {Promise<string>} The base64 data string of the image
     */
    private static getBase64FromFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event: ProgressEvent<FileReader>) => {
                resolve(event.target?.result as string);
            };

            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };
}
