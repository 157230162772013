import { ChatHistoryEntryInterface } from "@dashart/dashart-gpt-shared-library";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../app/models/RecoilStates";
import { ChatHistoryService } from "../../../app/services/ChatHistoryService";
import { ChatGptIcon } from "../icons/ChatGptIcon";
import { ClaudeIcon } from "../icons/ClaudeIcon";
import { ContractIcon } from "../icons/ContractIcon";
import { MistralIcon } from "../icons/MistralIcon";
import { ExpandIcon } from "../icons/ExpandIcon";
import styles from "./ChatHistory.module.scss";
import { ChatModelInterface } from "../../../app/interfaces/ChatModelInterface";
import { GeminiIcon } from "../icons/GeminiIcon";
import { ChatHistoryHocProps } from "./ChatHistoryHoc";

export interface ChatHistoryProps {
    expanded?: boolean;
    toggleExpanded?: () => void;
    onEntrySelected?: (conversationId: string, model: string) => void;

    historyEntries: {
        currentPageNumber: number;
        moreItemsAvailable: boolean;
        keyToIndexMap: { [key: string]: number };
        entries: ChatHistoryEntryInterface[];
    };
    searchHistoryEntries: {
        currentPageNumber: number;
        moreItemsAvailable: boolean;
        keyToIndexMap: { [key: string]: number };
        entries: ChatHistoryEntryInterface[];
    };
    availableModels: ChatModelInterface[];
    lastUsedConversationId: string;
    currentSearchValue: string;
    mode: "history" | "search";
    moreItemsAvailable: boolean;
    searchFieldOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onLoadMoreClickHandler: () => void;
    loadHistoryEntries: (page: number, limit: number, resetList: boolean) => Promise<void>;
    loadSearchResultEntries: (searchValue: string, page: number, limit: number, resetList: boolean) => Promise<void>;
}


const ChatHistory: React.FunctionComponent<ChatHistoryProps> = (props) => {
    const { t } = useTranslation();
    const {
        expanded,
        toggleExpanded,
        historyEntries,
        searchHistoryEntries,
        availableModels,
        lastUsedConversationId,
        mode,
        moreItemsAvailable,
        searchFieldOnChange,
        onLoadMoreClickHandler,
    } = props;

    const renderedLoadMoreButton = React.useMemo(() => {
        return (
            <div className={styles.LoadMoreButton} onClick={onLoadMoreClickHandler}>
                <Button
                    label={t("chat_screen.chat_history.load_more")}
                    className={"p-button-raised p-button-secondary p-button-rounded w-full"}
                />
            </div>
        );
    }, [mode, historyEntries, searchHistoryEntries]);

    const renderedExpandToggleButtons = React.useMemo(() => {
        return (
            <div className={styles.ExpandToggleButtons} onClick={toggleExpanded}>
                {expanded ? (
                    <ContractIcon className={styles.ContractIcon} width={24} height={24} />
                ) : (
                    <ExpandIcon className={styles.ExpandIcon} width={24} height={24} />
                )}
            </div>
        );
    }, [expanded]);

    const renderListEntry = (entry, index) => {
        const size = {
            className: styles.icon,
            width: 15,
            height: 15
        };

        let icon;
        if (entry.model.startsWith('claude')) {
            icon = <ClaudeIcon
                {...size}
            />
        } else if (entry.model.startsWith('gpt') || entry.model.startsWith('o')) {
            icon = <ChatGptIcon
                {...size}
            />
        } else if (entry.model.startsWith('mistral')) {
            icon = <MistralIcon
                {...size}
            />
        } else if (entry.model.startsWith('gemini')) {
            icon = <GeminiIcon
                {...size}
            />
        }

        return <div
            className={styles.listEntry}
            key={entry.conversationId}
            onClick={() => {
                props.onEntrySelected(
                    entry.conversationId,
                    entry.model
                )
            }}
        >
            <>
                {icon}
            </>
            <>
                {entry.excerpt}
            </>
        </div>
    };

    const renderedList = React.useMemo(() => {
        const entries = mode === 'history' ? historyEntries.entries : searchHistoryEntries.entries;
        const renderedListEntries = [];

        let lastDate = null;
        entries?.forEach((entry, index) => {
            const date = new Date(entry.updatedAt);

            if (!lastDate || lastDate.getDate() !== date.getDate()) {
                const dateString = date.toLocaleDateString();
                lastDate = date;

                renderedListEntries.push(<div
                    className={styles.listEntryDayLabel}
                    key={dateString}
                >
                    {dateString}
                </div>);
            }

            renderedListEntries.push(renderListEntry(entry, index));
        });

        // render list entries, but add a day label before the first entry of each day
        return <div className={styles.listSection}>
            {renderedListEntries}
        </div>
    }, [lastUsedConversationId, historyEntries, searchHistoryEntries, mode, availableModels]);

    const renderedSearchField = React.useMemo(() => {
        return (
            <div className={styles.searchField}>
                <span className="p-input-icon-right w-full">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={t("chat_screen.chat_history.search_prompt")}
                        className="w-full p-1"
                        onChange={searchFieldOnChange}
                    />
                </span>
            </div>
        );
    }, []);

    return (
        <div className={styles.ChatHistory}>
            {renderedExpandToggleButtons}

            {expanded ? (
                <div className={styles.listContainer}>
                    {renderedSearchField}

                    <div className={styles.listTitle}>
                        {mode == "history"
                            ? t("chat_screen.chat_history.title_history")
                            : t("chat_screen.chat_history.title_search_result")}
                    </div>
                    {renderedList}

                    {moreItemsAvailable ? renderedLoadMoreButton : null}
                </div>
            ) : null}
        </div>
    );
};

export default ChatHistory;