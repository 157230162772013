import { ToastMessageInterface } from "@app/interfaces/ToastMessageInterface";
import { ModelCardInterface } from "@dashart/dashart-gpt-shared-library";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import { atom, RecoilState } from "recoil";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist()

export default class RecoilStates {
    public static currentUserState:RecoilState<UserInterface> = atom({
        key: 'currentUserState',
        default: null,
    });
    public static availableModels:RecoilState<ModelCardInterface[]> = atom({
        key: 'availableModelsState',
        default: [],
        effects_UNSTABLE: [persistAtom],
    });
    
    public static selectedModelsByServiceNames:RecoilState<{
        [key:string]: ModelCardInterface,
    }> = atom({
        key: 'selectedModelState',
        default: {},
        effects_UNSTABLE: [persistAtom],
    });
    public static lastToastMessageState:RecoilState<ToastMessageInterface> = atom({
        key: 'lastToastMessageState',
        default: null,
    });
    public static lastUsedConversationId:RecoilState<string> = atom({
        key: 'lastUsedConversationId',
        default: null,
    });
    public static chatHistoryOpenState:RecoilState<boolean> = atom({
        key: 'chatHistoryOpenState',
        default: true,
        effects_UNSTABLE: [persistAtom],
    });
    public static instructionsSelectorDialogTabIndexState:RecoilState<number> = atom({
        key: 'instructionsSelectorDialogTabIndexState',
        default: 0,
        effects_UNSTABLE: [persistAtom],
    });
    public static lastUpdatedInstructionIdAndTsState:RecoilState<string | null> = atom({
        key: 'lastUpdatedInstructionIdAndTsState',
        default: null,
        effects_UNSTABLE: [persistAtom],
    });
}