import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../app/models/RecoilStates";
import { ChatHistoryService } from "../../../app/services/ChatHistoryService";
import { ChatHistoryEntryInterface } from "@dashart/dashart-gpt-shared-library";
import { ChatModelInterface } from "../../../app/interfaces/ChatModelInterface";
import ChatHistory from "./ChatHistory";

const LIMIT = 25;
const SEARCH_MINIMUM_LENGTH = 3;
const SEARCH_DEBOUNCE_IN_MS = 200;

export interface ChatHistoryHocProps {
    expanded?: boolean;
    toggleExpanded?: () => void;
    onEntrySelected?: (conversationId: string, model: string) => void;
}

export interface ChatHistoryHoc extends ChatHistoryHocProps {
    historyEntries: {
        currentPageNumber: number;
        moreItemsAvailable: boolean;
        keyToIndexMap: { [key: string]: number };
        entries: ChatHistoryEntryInterface[];
    };
    searchHistoryEntries: {
        currentPageNumber: number;
        moreItemsAvailable: boolean;
        keyToIndexMap: { [key: string]: number };
        entries: ChatHistoryEntryInterface[];
    };
    availableModels: ChatModelInterface[];
    lastUsedConversationId: string;
    currentSearchValue: string;
    mode: "history" | "search";
    moreItemsAvailable: boolean;
    searchFieldOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onLoadMoreClickHandler: () => void;
    loadHistoryEntries: (page: number, limit: number, resetList: boolean) => Promise<void>;
    loadSearchResultEntries: (searchValue: string, page: number, limit: number, resetList: boolean) => Promise<void>;
}

export const ChatHistoryHoc = (props: ChatHistoryHocProps) => {
    const { t } = useTranslation();
    const { expanded } = props;

    const [availableModels, setAvailableModels] = useRecoilState<ChatModelInterface[]>(RecoilStates.availableModels);
    const [historyEntries, setHistoryEntries] = React.useState<{
        currentPageNumber: number;
        moreItemsAvailable: boolean;
        keyToIndexMap: { [key: string]: number };
        entries: ChatHistoryEntryInterface[];
    }>({
        currentPageNumber: 1,
        moreItemsAvailable: true,
        keyToIndexMap: {},
        entries: [],
    });
    const [searchHistoryEntries, setSearchHistoryEntries] = React.useState<{
        currentPageNumber: number;
        moreItemsAvailable: boolean;
        keyToIndexMap: { [key: string]: number };
        entries: ChatHistoryEntryInterface[];
    }>({
        currentPageNumber: 1,
        moreItemsAvailable: true,
        keyToIndexMap: {},
        entries: [],
    });
    const [lastUsedConversationId] = useRecoilState(RecoilStates.lastUsedConversationId);
    const [currentSearchValue, setCurrentSearchValue] = React.useState<string>("");
    const [mode, setMode] = React.useState<"history" | "search">("history");

    React.useEffect(() => {
        loadHistoryEntries(1, LIMIT, false);
    }, [lastUsedConversationId]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (currentSearchValue?.length >= SEARCH_MINIMUM_LENGTH) {
                loadSearchResultEntries(currentSearchValue, 1, LIMIT, true);
            }
        }, SEARCH_DEBOUNCE_IN_MS);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentSearchValue]);

    const moreItemsAvailable = React.useMemo(() => {
        return mode === "history" ? historyEntries.moreItemsAvailable : searchHistoryEntries.moreItemsAvailable;
    }, [mode, historyEntries, searchHistoryEntries]);

    const searchFieldOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = (e.target.value ?? "").trim();
        const searchActive = searchValue.length >= SEARCH_MINIMUM_LENGTH;

        setCurrentSearchValue(searchActive ? searchValue : null);
        setMode(searchActive ? "search" : "history");
    };

    const onLoadMoreClickHandler = () => {
        if (mode === "history") {
            loadHistoryEntries(historyEntries.currentPageNumber + 1, LIMIT, false);
        } else {
            loadSearchResultEntries(currentSearchValue, searchHistoryEntries.currentPageNumber + 1, LIMIT, false);
        }
    };

    const addNewEntriesToList = (
        newEntries: ChatHistoryEntryInterface[],
        pageNumber: number,
        moreItemsAvailable: boolean,
        resetEntries = false
    ) => {
        const source = mode === 'history' ? historyEntries : searchHistoryEntries;

        if (resetEntries) {
            source.entries = [];
            source.keyToIndexMap = {};
        }

        const updatedEntries = [
            ...source.entries,
            // only add entries that are not already in the list
            ...newEntries.filter((entry) => {
                return !source.keyToIndexMap[entry.conversationId];
            })
        ].filter((entry, index, self) => {
            // remove duplicates
            return self.findIndex((e) => e.conversationId === entry.conversationId) === index;
        });

        // sort the entries by updatedAt
        updatedEntries.sort((a, b) => {
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        });

        // refresh the key to index map
        const keyToIndexMap = {};
        updatedEntries.forEach((entry, index) => {
            keyToIndexMap[entry.conversationId] = index;
        });


        if (mode === 'history') {
            setHistoryEntries({
                currentPageNumber: pageNumber,
                moreItemsAvailable: moreItemsAvailable,
                keyToIndexMap,
                entries: updatedEntries
            });
        } else {
            setSearchHistoryEntries({
                currentPageNumber: pageNumber,
                moreItemsAvailable: moreItemsAvailable,
                keyToIndexMap,
                entries: updatedEntries
            });
        }
    };

    const loadHistoryEntries = async (page: number, limit: number, resetList: boolean) => {
        const historyResult = await ChatHistoryService.getInstance().getConversationHistoryListPaginated(page, limit);
        addNewEntriesToList(historyResult.data, page, historyResult.meta.next_page_url !== null, resetList);
    };

    const loadSearchResultEntries = async (searchValue: string, page: number, limit: number, resetList: boolean) => {
        const searchResult = await ChatHistoryService.getInstance().searchConversationHistoryList(searchValue, page, limit);
        addNewEntriesToList(searchResult.data, page, searchResult.meta.next_page_url !== null, resetList);
    };

    return (
        <ChatHistory
            {...props}
            historyEntries={historyEntries}
            searchHistoryEntries={searchHistoryEntries}
            availableModels={availableModels}
            lastUsedConversationId={lastUsedConversationId}
            currentSearchValue={currentSearchValue}
            mode={mode}
            moreItemsAvailable={moreItemsAvailable}
            searchFieldOnChange={searchFieldOnChange}
            onLoadMoreClickHandler={onLoadMoreClickHandler}
            loadHistoryEntries={loadHistoryEntries}
            loadSearchResultEntries={loadSearchResultEntries}
        />
    );
};
