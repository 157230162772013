import { VisionPromptContentImageInterface } from "@dashart/dashart-gpt-shared-library";
import { classNames } from 'primereact/utils';
import * as React from "react";
import { ChangeEvent, DragEvent, memo, ReactElement, useCallback, useState } from 'react';
import './ImageFileSelector.scss';
import ImagePromptUtil from "../../../app/utils/ImagePromptUtil";

interface ImageFileSelectorProps {
    image: VisionPromptContentImageInterface | null;
    onChange: (currentImageValue: VisionPromptContentImageInterface | null, newImageValue: VisionPromptContentImageInterface | null) => void;
    className?: string;
    dropZone?: ReactElement; // Custom drop zone element
}

// SVG Plus Icon component
const PlusIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <line x1="12" y1="5" x2="12" y2="19" />
        <line x1="5" y1="12" x2="19" y2="12" />
    </svg>
);

const ImageFileSelector: React.FC<ImageFileSelectorProps> = memo(({
    image: currentImage,
    onChange,
    className,
    dropZone
}) => {
    const [imagePreview, setImagePreview] = useState<VisionPromptContentImageInterface | null>(currentImage);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [inputKey, setInputKey] = useState(0);

    // reset the image preview when the image changes
    React.useEffect(() => {
        setImagePreview(currentImage);
    }, [currentImage]);

    // Reset file input when image becomes null
    React.useEffect(() => {
        if (currentImage === null) {
            setInputKey(prevKey => prevKey + 1);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }, [currentImage]);

    const handleFileInput = useCallback(
        async (file: File) => {
            if (!file?.type.startsWith('image/')) {
                console.warn('Invalid file type. Please select an image file.');
                return;
            }

            try {
                const imageObject = await ImagePromptUtil.resizeImageFile(
                    file,
                    ImagePromptUtil.IMAGE_CONFIG.MAX_WIDTH,
                    ImagePromptUtil.IMAGE_CONFIG.MAX_HEIGHT
                );
                setImagePreview(imageObject);
                onChange(currentImage, imageObject);
            } catch (error) {
                console.error('Error processing image:', error);
            }
        },
        [onChange]
    );

    const onFileSelect = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (file) {
                handleFileInput(file);
            }
        },
        [handleFileInput, onChange]
    );

    const onIconClick = useCallback(() => {
        fileInputRef.current?.click();
    }, []);

    const onDrop = useCallback(
        (e: DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            const file = e.dataTransfer.files?.[0];
            if (file) {
                handleFileInput(file);
            }
        },
        [handleFileInput]
    );

    const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }, []);

    const removeImage = useCallback(() => {
        onChange(currentImage, null);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }, [onChange]);

    // Create wrapped drop zone with drag and drop handlers
    const wrappedDropZone = dropZone ? (
        <div
            onDrop={onDrop}
            onDragOver={onDragOver}
            style={{ cursor: 'pointer' }}
        >
            {React.cloneElement(dropZone, {
                onClick: onIconClick
            })}
        </div>
    ) : null;

    return (
        <div className={classNames('ImageFileSelector', className)}>
            <input
                key={inputKey}
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={onFileSelect}
                style={{ display: 'none' }}
            />

            {!imagePreview ? (
                wrappedDropZone || (
                    <div
                        className="dropzone p-3 my-2 border-1 border-round d-flex align-items-center justify-content-center"
                        style={{ cursor: 'pointer' }}
                        onClick={onIconClick}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                    >
                        <div className="plus-icon-wrapper">
                            <PlusIcon />
                        </div>
                    </div>
                )
            ) : (
                <div className="previews-area d-flex flex-column">
                    <div className="preview-container">
                        <img
                            src={imagePreview.imageUrl}
                            alt="Preview"
                            className="preview-image"
                        />
                        <i
                            className="pi pi-times remove-icon"
                            onClick={removeImage}
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

ImageFileSelector.displayName = 'ImageFileSelector';

export default ImageFileSelector;