import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Menubar } from "primereact/menubar";
import { Message } from "primereact/message";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { RoutesEnum } from "@app/models/RoutesEnum";
import BaseInput, { BaseInputType } from "@view/components/inputs/baseInput/BaseInput";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import { ValidationError } from "class-validator";
import "./LoginScreen.scss";

export interface LoginScreenProps {
    loading: boolean;
    loginFailed: boolean;
    overlay: boolean;

    // texts
    headerText: string;
    loginFailedText: string;
    loginButtonText: string;
    cancelButtonText: string;
    resetButtonText: string;
    userNameLabelText: string;
    passwordLabelText: string;

    // valdiation messages
    userNameRequiredMessage: string;
    userNameEmailMessage: string;
    passwordRequiredMessage: string;
    passwordMinLengthMessage: string;

    login: (userName: string, password: string) => void;
}

interface FormData {
    userName: string;
    password: string;
}

const LoginScreen: React.FC<LoginScreenProps> = (props) => {
    const { t } = useTranslation();
    const [redirectTo, setRedirectTo] = useState<string | null>(null);
    const [formData, setFormData] = useState<FormData>({ userName: "", password: "" });
    const [dialogStyle, setDialogStyle] = useState({ width: '50vw' });
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

    /**
     * Validate the config element
     */
    const validate = React.useCallback((formData: FormData) => {
        // apply rules
        const errors: ValidationError[] = [];

        // check if user name is empty
        if ((formData.userName ?? '').trim() === '') {
            errors.push({ property: 'userName', constraints: { isRequired: props.userNameRequiredMessage } });
        }

        // check if user name is a valid email
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.userName)) {
            errors.push({ property: 'userName', constraints: { isEmail: props.userNameEmailMessage } });
        }

        // check if password is empty
        if ((formData.password ?? '').trim() === '') {
            errors.push({ property: 'password', constraints: { isRequired: props.passwordRequiredMessage } });
        }

        // check if password is at least 4 characters long
        if ((formData.password ?? '').length < 4) {
            errors.push({ property: 'password', constraints: { isMinLength: props.passwordMinLengthMessage } });
        }

        setValidationErrors(errors)
    }, []);

    /**
     * Resize the dialog
     */
    useEffect(() => {
        const handleResize = () => {
            setDialogStyle({ width: window.innerWidth < 1024 ? '100vw' : '50vw' });
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Validate the form data
     */
    useEffect(() => {
        validate(formData);
    }, [formData]);

    /**
     * Update the form data
     */
    const updateFormData = useCallback((field: keyof FormData, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    /**
     * Clear the form data
     */
    const onClearHandler = useCallback(() => setFormData({ userName: "", password: "" }), []);

    /**
     * Login the user
     */
    const onLoginHandler = useCallback(() => props.login(formData.userName, formData.password), [props.login, formData]);

    /**
     * Redirect to the forgotten password screen
     */
    const onResetHandler = useCallback(() => setRedirectTo(RoutesEnum.forgottenPassword), []);

    /**
     * Handle the outer key press
     */
    const onOuterKeyHandler = useCallback((evt: React.KeyboardEvent) => {
        if (evt.code.toLowerCase() === "enter" && validationErrors.length === 0) {
            onLoginHandler();
        }
    }, [onLoginHandler, validationErrors]);

    /**
     * Render the dialog content
     */
    const renderDialogContent = () => (
        <Dialog
            header={props.headerText}
            visible={true}
            style={dialogStyle}
            modal
            closable={false}
            onHide={() => { }}
        >
            <div className="LoginScreen formgrid grid">
                {redirectTo && <Navigate to={redirectTo} />}
                <ProgressSpinnerOverlay visible={props.loading} />
                <div className="col-12 p-fluid col-nogutter" onKeyPressCapture={onOuterKeyHandler}>
                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes="col-12"
                        label={props.userNameLabelText}
                        value={formData.userName}
                        valueName="userName"
                        // only set form errors if the value is not empty
                        formErrors={(formData.userName ?? '') !== '' ? validationErrors : []}
                        handlerChange={(data: string) => updateFormData('userName', data)}
                    />
                    <BaseInput
                        type={BaseInputType.Password}
                        classes="col-12"
                        label={props.passwordLabelText}
                        value={formData.password}
                        valueName="password"
                        // only set form errors if the value is not empty
                        formErrors={(formData.password ?? '') !== '' ? validationErrors : []}
                        handlerChange={(data: string) => updateFormData('password', data)}
                    />
                    {props.loginFailed && (
                        <div className="col-12 mb-2">
                            <Message severity="error" text={props.loginFailedText} />
                        </div>
                    )}
                </div>
                <Menubar
                    className="col-12 loginMenubar"
                    model={[]}
                    end={
                        <>
                            <Button
                                className="p-button-secondary ml-2"
                                label={props.cancelButtonText}
                                onClick={onClearHandler}
                                icon="pi pi-times"
                            />
                            <Button
                                className="ml-2"
                                label={props.loginButtonText}
                                onClick={onLoginHandler}
                                icon="pi pi-check"
                                disabled={validationErrors.length > 0}
                            />
                        </>
                    }
                />
            </div>
        </Dialog>
    );

    return props.overlay ? renderDialogContent() : (
        <div className="LoginBox flex p-grid col align-items-center justify-content-center" data-testid="LoginBoxScreen">
            <div className="col-8">
                {renderDialogContent()}
            </div>
        </div>
    );
};

export default LoginScreen;