import { ModelCardInterface, VisionPromptContentImageInterface } from '@dashart/dashart-gpt-shared-library';
import ButtonWithIcon from "@view/components/buttonWithIcon/ButtonWithIcon";
import { CancelIcon } from "@view/components/icons/CancelIcon";
import { SendIcon } from "@view/components/icons/SendIcon";
import { InputTextarea } from "primereact/inputtextarea";
import * as React from 'react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ImageFileSelector from '../imageFileSelector/ImageFileSelector';
import ImagePromptUtil from '../../../app/utils/ImagePromptUtil';

interface ChatInputProps {
    model: ModelCardInterface;
    prompt: string;
    images: VisionPromptContentImageInterface[];
    isRunning: boolean;
    textAreaLines: number;

    onPromptChange: (prompt: string) => void;
    onImagesChange: (images: VisionPromptContentImageInterface[]) => void;
    onSubmit: () => void;
    onCancel: () => void;
}

const ICON_SIZE = 24;

export const ChatInput = memo(({
    prompt,
    model,
    images,
    isRunning,
    textAreaLines,
    onPromptChange,
    onImagesChange,
    onSubmit,
    onCancel
}: ChatInputProps) => {
    const { t } = useTranslation();

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && event.metaKey) {
            onSubmit();
        }
    }, [onSubmit]);

    /**
     * Handle image change.
     * If the image is already in the list, replace it with the new image.
     * If the image is not in the list, add it.
     * @param currentImage 
     * @param newImage 
     */
    const handleImageChange = useCallback((currentImage: VisionPromptContentImageInterface, newImage: VisionPromptContentImageInterface | null) => {
        const newImages = [...images];
        
        if (currentImage) {
            // replace current image with new image
            const index = newImages.findIndex((image) => image.imageUrl === currentImage.imageUrl);
            if (index !== -1) {
                newImages[index] = newImage;
            } else {
                // add new image if it is not already in the list
                newImages.push(newImage);
            }
        } else {
            // add new image
            newImages.push(newImage);
        }

        onImagesChange(newImages);
    }, [onImagesChange, images]);

    /**
     * Handle paste event, add images to the list
     * @param event 
     * @returns 
     */
    const handlePaste = useCallback(
        async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
            const clipboardItems = Array.from(event.clipboardData.items);
            const imageItems = clipboardItems.filter(item => item.type.indexOf('image') !== -1);
    
            if (imageItems.length === 0) {
                return;
            }
    
            // Prevent default paste only if we have images
            event.preventDefault();
    
            try {
                // Process all images in parallel
                const newImages = await Promise.all(
                    imageItems.map(async (item) => {
                        const blob = item.getAsFile();
                        if (!blob) {
                            return null;
                        }
    
                        return ImagePromptUtil.resizeImageFile(
                            blob,
                            ImagePromptUtil.IMAGE_CONFIG.MAX_WIDTH,
                            ImagePromptUtil.IMAGE_CONFIG.MAX_HEIGHT
                        );
                    })
                );
    
                // Filter out any null results and add to existing images
                const validNewImages = newImages.filter((img): img is VisionPromptContentImageInterface => img !== null);
                
                if (validNewImages.length > 0) {
                    onImagesChange([
                        ...(images ?? []),
                        ...validNewImages
                    ]);
                }
            } catch (error) {
                console.error('Error processing pasted images:', error);
            }
        },
        [images, onImagesChange]
    );

    /**
     * Render image selectors
     * @returns 
     */
    const renderImageSelectors = React.useMemo(() => {
        // render one empty if no images
        if (!images || images.length === 0) {
            return <ImageFileSelector
                image={null}
                onChange={handleImageChange}
            />;
        }

        return images.map((image, index) => (
            <ImageFileSelector
                key={index}
                image={image}
                onChange={handleImageChange}
            />
        ));
    }, [images, handleImageChange]);

    return (
        <div className="lower-section flex-grow-0 p-grid p-2 m-0 border-top-1">
            <div className="grid" onKeyDownCapture={handleKeyDown}>
                <div className="col-12 p-1 pb-0 mb-1 relative">
                    <InputTextarea
                        className='col-12 p-2 pr-6 m-0'
                        placeholder={t('components.chat_input.your_prompt')}
                        rows={textAreaLines}
                        value={prompt}
                        onChange={(e) => onPromptChange(e.currentTarget.value)}
                        onPaste={handlePaste}
                    />
                    <div className='inputActionButtons absolute right-0 top-0 m-2 flex flex-row gap-2'>
                        {model?.supportsVision && (
                            renderImageSelectors
                        )}
                    </div>
                    <div className='inputActionButtons absolute right-0 bottom-0 m-2'>
                        <ButtonWithIcon
                            icon={
                                !isRunning ?
                                    <SendIcon width={ICON_SIZE} height={ICON_SIZE} /> :
                                    <CancelIcon width={ICON_SIZE} height={ICON_SIZE} />
                            }
                            onClick={!isRunning ? onSubmit : onCancel}
                            aria-label={!isRunning ? 'send' : 'cancel'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

ChatInput.displayName = 'ChatInput';

