// ImagePreview.tsx
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import './ImagePreview.scss';

interface ImagePreviewProps {
    src: string;
    previewWidth?: number;
    previewHeight?: number;
    className?: string;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
    src,
    previewWidth = 150,
    previewHeight = 150,
    className = '',
}) => {
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const handlePreviewClick = () => {
        setShowOverlay(true);
    };

    return (
        <div className="ImagePreview">
            <div className="image-preview-container">
                <img
                    src={src}
                    width={previewWidth}
                    height={previewHeight}
                    className={`preview-image ${className}`}
                    onClick={handlePreviewClick}
                />
            </div>

            {showOverlay && (
                <Dialog
                    visible={true}
                    onHide={() => setShowOverlay(false)}
                    className="ImagePreviewOverlayDialog"
                    draggable={false}
                    style={{ width: '90vw', height: '90vh' }}
                >
                    <div className="full-image-container">
                        <img
                            src={src}
                            className="full-image"
                        />
                    </div>
                </Dialog>
            )}
        </div>
    );
};
